@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
 
  .title-2 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  
  .subtitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

 
  .title-3 {
   font-size: 14px;
   font-weight: 500;
   line-height: 21px;
  }


  .title-3-semibold {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
   }

  .title-2-regular {
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
  }

  .title-2-semibold {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
   }
  
   .header-1 {
    font-size: 56px;
    font-weight: 500;
    line-height: 84px;
   }

   .header-2 {
    font-size: 43px;
    font-weight: 500;
    line-height: 84px;
   }
   
   .header-3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
   }


   .header-3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
   }
  

   .sub-header {
    font-size: 24px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
   }
  
   .text-1 {
font-size: 24px;
font-weight: 500;
line-height: 36px;
   }

   .text-3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    
       }

   .p1 {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
   }

   .p2 {
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
   }


   .box-shadow {
    box-shadow: -2px 0px 36px rgba(0, 0, 0, 0.06);
   }

}


.search-input {
  box-sizing: border-box;
width: 100%;
height: 80px;
background: #FFFFFF;
border: 1px solid #979797;
box-shadow: 0px 4px 4px 2px rgba(151, 151, 151, 0.36);
border-radius: 24px;
}

body {
  margin: 0;
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr.divider {
  opacity: 0.5;
  border: 1px solid #979797;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
